import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Infie App – Track all kinds of investments in one place" />
    <h1>Infie App</h1>
    <p>
      Track all kinds of investments in one place.
    </p>
    <p>
      <code>Coming soon.</code>
    </p>
  </Layout>
)

export default IndexPage
